import TransferError from './TransferError'

/**
 * @example
 * ```json
 * {
 *   "statusCode": 404,
 *   "code": "APP_ERR_NOT_FOUND",
 *   "error": "Not Found",
 * }
 * ```
 */
export type ResponseJson = {
  statusCode: number,
  code: string,
  error: string,
  message?: string,
}

/**
 * API/ HTTP status code 4xx or 5xx (BadResponseException in Guzzle)
 * {@link https://github.com/directus/directus/tree/v9.19.1/api/src/exceptions}
 */
export default class ApiError extends TransferError {
  public name = 'ApiError'

  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    public response: Response,
    public responseJson?: ResponseJson,
  ) {
    super(request, 'An error occured while fetching the data')

    Object.setPrototypeOf(this, ApiError.prototype)
  }
}
