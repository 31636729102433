import { IonContent, IonPage } from '@ionic/react';
import { useState, useCallback } from 'preact/hooks';
import { useScoresStore } from '../../store/scoresStore';
import { useUserStore } from '../../store/userStore';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import { useIonViewEffect } from '../../hooks/useIonView';
import styles from './Ranking.module.scss';
import logo from '../../assets/logo.svg';

enum RankFilter {
  Max = 'Max',
  Avg = 'Avg'
}

// TODO: Move to pages/Rankings.tsx
const Ranking: preact.FunctionComponent = () => {
  const [currentFilter, setCurrentFilter] = useState<RankFilter>(RankFilter.Max);
  const { getScores, data, sortByAverage, sortByMax } = useScoresStore(
    (state) => ({
      sortByAverage: state.sortByAverage,
      sortByMax: state.sortByMax,
      getScores: state.getScores,
      data: state.data
    }));
  const userData = useUserStore((state) => state.data);

  const currentUserRef = useCallback((node: HTMLDivElement) => {
      if (node) {
        setTimeout(() => {
          node.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }, 0)
      }
  }, [])

  useIonViewEffect(() => {
    const today = new Date();
    const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    getScores(firstDayOfCurrentMonth, today)
      .then(() => {
        sortByMax();
      })
  }, [])

  const onSortByMaxClick = () => {
    sortByMax();
    setCurrentFilter(RankFilter.Max);
  }

  const onSortByAvgClick = () => {
    sortByAverage();
    setCurrentFilter(RankFilter.Avg);
  }

  return (
    <IonPage>
      <MenuHeader />
      <IonContent>
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <div className={styles.headerContainer}>
              <img src={logo} width="42" height="42" />
              <h3>Ranking miesiąca</h3>
            </div>
            <div className={styles.tableContainer}>
              <section>
                <header>
                  <div className={styles.col}>Miejsce</div>
                  <div className={styles.col}>Ksywka</div>
                  <div className={styles.col}>
                    <span
                      role="button"
                      onClick={onSortByMaxClick}
                    >
                      Max
                    </span>
                    {
                      currentFilter === RankFilter.Max ? (
                        <div className={styles.arrowsContainer}>
                          <i className={styles.arrowDown} />
                        </div>
                      ) : null
                    }
                    &nbsp;/&nbsp;
                    <span
                      role="button"
                      onClick={onSortByAvgClick}
                    >
                      Średnia
                    </span>
                    {
                      currentFilter === RankFilter.Avg ? (
                        <div className={styles.arrowsContainer}>
                          <i className={styles.arrowDown} />
                        </div>
                      ) : null
                    }
                  </div>
                </header>
                {
                  data?.map((result, index) =>
                    <div
                      key={result.player.id}
                      className={`${styles.row} ${result.player.id === userData?.id ? styles.currentUser : ''}`}
                      ref={result.player.id === userData?.id ? currentUserRef : undefined}
                    >
                      <div className={styles.col}>
                        <div className={styles.numberContainer}>
                          {index + 1}.
                        </div>
                      </div>
                      <div className={styles.col}>{result.player.nickName}</div>
                      <div className={styles.col}>{Math.round(result.max)}&nbsp;/&nbsp;{Math.round(result.avg)}</div>
                    </div>
                  )
                }
              </section>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Ranking;
