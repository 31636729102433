// Re-export
export { default as TransferError } from './TransferError'
export { default as ConnectError } from './ConnectError'
export { default as ApiError } from './ApiError'

export const ERROR_CODES = {
  BAD_REQUEST: 'APP_ERR_BAD_REQUEST',
  DATABASE: 'APP_ERR_DATABASE',
  NOT_FOUND: 'APP_ERR_NOT_FOUND',
  UNAUTHORIZED: 'APP_ERR_UNAUTHORIZED',
  FORBIDDEN: 'APP_ERR_FORBIDDEN'
}
