import {
  type ErrorInfo,
  Component,
} from 'preact'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonPage,
} from '@ionic/react'
import { ROUTES } from '../../navigation';

type ErrorBoundaryProps = {
  envMode: string,
}

type ErrorBoundaryState = {
  error: Error | null,
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
  }

  static getDerivedStateFromError(error: Error): Pick<ErrorBoundaryState, 'error'> {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // TODO: Log error
  }

  render() {
    if (!this.state.error) {
      return (
        <>
          {this.props.children}
        </>
      )
    }

    return (
      <IonPage className="m1-page m1-page--error">
        <IonContent>
          <div className="m1-page__error-wrapper">
            <IonCard className="m1-card">
              <IonCardHeader>
                <IonCardTitle>
                  {'Błąd aplikacji'}
                </IonCardTitle>
              </IonCardHeader>
              {this.props.envMode !== 'production' &&
                <IonCardContent>
                  <code>
                    {this.state.error.name}<br />
                    {this.state.error.message}
                  </code>
                </IonCardContent>
              }
              <IonButton
                color="primary"
                fill="solid"
                routerLink={ROUTES.GAME_START}
                routerDirection="none"
              >
                {'Zrestartuj aplikację'}
              </IonButton>
            </IonCard>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default ErrorBoundary
