import { useEffect } from 'preact/hooks'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { Capacitor } from '@capacitor/core'

const isPullRequestPreview = import.meta.env.VITE_HOSTING_IS_PULL_REQUEST === 'true'

/**
 * @link https://www.npmjs.com/package/@codetrix-studio/capacitor-google-auth
 */
const GoogleAuthProvider: preact.FunctionComponent<{ clientId: string }> = ({ clientId, children }) => {
  const isNativePlatform = Capacitor.isNativePlatform()

  useEffect(() => {
    if (isNativePlatform || isPullRequestPreview) {
      return
    }

    GoogleAuth.initialize({
      clientId,
      scopes: ['email', 'profile'],
      grantOfflineAccess: true,
    })
  }, [isNativePlatform, clientId])

  return children as JSX.Element
}

export default GoogleAuthProvider
