import { IonContent, IonPage } from '@ionic/react';
import { useHistory } from 'react-router'
import { useIonViewEffect } from '../../hooks/useIonView';
import { ROUTES } from '../../navigation';
import logoSvg from '../../assets/logo.svg';

import styles from './SplashScreen.module.scss';

const SplashScreen: preact.FunctionComponent = () => {
  const history = useHistory();

  useIonViewEffect(() => {
    const timer = setTimeout(() => history.push(ROUTES.GAME_START), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <IonPage>
      <IonContent className={styles.splashScreen} forceOverscroll={false} fullscreen>
        <img className={styles.logo} src={logoSvg} width="160" height="160" alt="logo" />
      </IonContent>
    </IonPage>
  );
};

export default SplashScreen;
