import { IonToolbar, IonHeader, IonButtons, IonMenuButton, IonBackButton } from '@ionic/react';
import { useLocation } from 'react-router'
import { ROUTES } from '../../navigation';
import styles from './MenuHeader.module.scss';

const MenuHeader: preact.FunctionComponent = () => {
  const location = useLocation();

  return (
    <IonHeader>
      <IonToolbar className={styles.menuHeader}>
        <IonButtons slot="end">
          <IonMenuButton color='dark' />
        </IonButtons>
        {
          location.pathname !== ROUTES.GAME_START && (
            <IonButtons slot="start">
              <IonBackButton className={styles.backButton} defaultHref='/game-start' color='dark' />
            </IonButtons>
          )
        }
      </IonToolbar>
    </IonHeader>
  );
};

export default MenuHeader;
