import { useState } from 'preact/hooks';
import { useHistory } from 'react-router'
import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent } from '@ionic/react';
import { shallow } from 'zustand/shallow'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { useUserStore } from '../../store/userStore';

import styles from './DeleteAccounts.module.scss';

const DeleteAccount: preact.FunctionComponent = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userData, delete: deleteUser } = useUserStore(
    (state) => ({
      delete: state.delete,
      userData: state.data
    }),
    shallow
  )

  const onDeleteAccountClick = async () => {
    if(userData?.id) {
      deleteUser(userData.id)
      GoogleAuth.signOut()
    }
  }

  return (
    <div className={styles.container}>
      <IonButton color="dark" type="submit" onClick={() => setIsModalOpen(true)}>Skasuj konto</IonButton>
      <IonModal isOpen={isModalOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Jesteś pewny?</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsModalOpen(false)}>Zamknij</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonButton expand="block" onClick={async () => {
            await onDeleteAccountClick();
            setIsModalOpen(false)
          }}>
            Usuń
          </IonButton>
          <IonButton expand="block" onClick={() => {
            setIsModalOpen(false)
          }}>
            Anuluj
          </IonButton>
        </IonContent>
      </IonModal>
    </div>
  )
};

export default DeleteAccount;
