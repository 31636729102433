import { useState } from 'preact/hooks';
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonPage,
  IonLabel,
  IonLoading,
  IonAlert,
  IonRadioGroup,
  IonRadio,
  IonSelect,
  IonSelectOption
} from "@ionic/react";
import { Formik } from "formik";
import * as yup from "yup";
import type { TestConfig } from 'yup';
import { useUserStore } from '../../store/userStore';
import DeleteAccount from '../../components/DeleteAccount/DeleteAccount';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import { AgeCategory, Sex, MenWeightCategory, WomenWeightCategory } from '../../store/userStore';
import styles from './Profile.module.scss'
import { forbiddenWordsPL } from '../../utils/Vocabulary'

const MEN_WEIGHT_CATEGORIES = [
  {
    "value": MenWeightCategory.LTE_57,
    "label": "do 57 kg"
  },
  {
    "value": MenWeightCategory.R_58_66,
    "label": "58-66 kg"
  },
  {
    "value": MenWeightCategory.R_67_77,
    "label": "67-77 kg"
  },
  {
    "value": MenWeightCategory.R_78_93,
    "label": "78-93 kg"
  },
  {
    "value": MenWeightCategory.GTE_93,
    "label": "od 94 kg"
  }
]

const WOMEN_WEIGHT_CATEGORIES = [
  {
    "value": WomenWeightCategory.LTE_52,
    "label": "do 52 kg"
  },
  {
    "value": WomenWeightCategory.R_53_65,
    "label": "53-65 kg"
  },
  {
    "value": WomenWeightCategory.GTE_66,
    "label": "od 66 kg"
  }
]

const curseTestOptions: TestConfig<string | undefined> = {
  name: 'test-curse',
  message: 'Nie akceptujemy wulgaryzmów',
  test: value => !forbiddenWordsPL.some(fWord => value?.toLowerCase().includes(fWord)),
}

const validationSchema = yup.object({
  firstName: yup
    .string()
    .max(16, 'Imię jest za długie')
    .test(curseTestOptions)
    .required('Imię jest wymagane'),
  lastName: yup
    .string()
    .test(curseTestOptions)
    .required('Nazwisko jest wymagane'),
  nickName: yup
    .string()
    .max(16, 'Ksywka jest za długa')
    .test(curseTestOptions)
    .required('Ksywka jest wymagana'),
  sex: yup
    .string()
    .nullable(),
  ageCategory: yup
    .string()
    .nullable(),
  weightCategory: yup
    .string()
    .nullable(),
  email: yup
    .string()
    .email('Wprowadź poprawny adres email')
    .required('Adres email jest wymagany'),
});

const Profile: preact.FunctionComponent = () => {
  const { update, userData, isFetchingData } = useUserStore(
    (state) => ({
      update: state.update,
      userData: state.data,
      isFetchingData: state.isFetchingData,
    })
  )

  const [hasNickNameError, setHasNickNameError] = useState(false);
  const [showProfileUpdated, setShowProfileUpdated] = useState(false);

  const onSubmit = async (values: any) => {
    try {
      if (userData) {
        await update({
          id: userData.id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          nickName: values.nickName,
          sex: values.sex,
          ageCategory: values.ageCategory,
          weightCategory: values.weightCategory
        })
        setShowProfileUpdated(true);
        setHasNickNameError(false);
      }
    } catch(e: any) {
      if (e.code === 'APP_ERR_PLAYER_NICKNAME_TAKEN') {
        setHasNickNameError(true);
      }
    }
  }

  return (
    <IonPage>
      <MenuHeader />
      <IonContent>
        {!isFetchingData &&
          <Formik
            initialValues={{
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              nickName: userData?.nickName || null,
              sex: userData?.sex || null,
              ageCategory: userData?.ageCategory || null,
              weightCategory: userData?.weightCategory || null,
              email: userData?.email,
            }}
            validationSchema={validationSchema}
            validateOnMount
            onSubmit={onSubmit}
          >
            {formikProps => (
              <IonContent>
                <div className={styles.formContainer}>
                  <form onSubmit={formikProps.handleSubmit}>
                    <IonItem color="none">
                      <IonInput
                        label="Imię"
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="Imie"
                        value={formikProps.values.firstName}
                        onIonInput={formikProps.handleChange}
                      />
                      {formikProps.errors.firstName ? (
                        <div className={styles.error}>{formikProps.errors.firstName}</div>
                      ) : null}
                    </IonItem>
                    <IonItem color="none">
                      <IonInput
                        label="Nazwisko"
                        type="text"
                        placeholder="Nazwisko"
                        name="lastName"
                        value={formikProps.values.lastName}
                        onIonInput={formikProps.handleChange}
                      />
                      {formikProps.errors.lastName ? (
                        <div className={styles.error}>{formikProps.errors.lastName}</div>
                      ) : null}
                    </IonItem>
                    <IonItem color="none">
                      <IonInput
                        label="Ksywka"
                        type="text"
                        placeholder="Ksywka"
                        name="nickName"
                        value={formikProps.values.nickName}
                        onIonInput={formikProps.handleChange}
                      />
                      {formikProps.errors.nickName ? (
                        <div className={styles.error}>{formikProps.errors.nickName}</div>
                        ) : hasNickNameError ? (
                          <div className={styles.error}>Ksywka jest już w użyciu</div>
                        ) : null
                      }
                    </IonItem>
                    <IonItem color="none">
                      <IonInput
                        label="Email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formikProps.values.email}
                        onIonInput={formikProps.handleChange}
                      />
                      {formikProps.errors.email ? (
                        <div className={styles.error}>{formikProps.errors.email}</div>
                      ) : null}
                    </IonItem>
                    <IonItem color="none">
                      <IonLabel className={styles.labels}>Płeć</IonLabel>
                      <IonRadioGroup className={styles.radioGroup} onIonChange={(e) => {
                        formikProps.setFieldValue("sex", e.detail.value)
                        formikProps.setFieldValue("weightCategory", null)
                      }} value={formikProps.values.sex}>
                        <IonRadio mode="md" value={Sex.M}>M</IonRadio>
                        <br />
                        <IonRadio mode="md" value={Sex.F}>K</IonRadio>
                      </IonRadioGroup>
                    </IonItem>
                    <IonItem color="none">
                      <IonSelect
                        className={styles.labels}
                        value={formikProps.values.ageCategory}
                        onIonChange={(e) => {
                          formikProps.setFieldValue("ageCategory", e.detail.value)
                        }}
                        label="Kategoria wiekowa"
                        placeholder="Kategoria"
                      >
                        <IonSelectOption value={AgeCategory.Youth}>do 16 lat: Kadet</IonSelectOption>
                        <IonSelectOption value={AgeCategory.Junior}>16-18 lat: Junior</IonSelectOption>
                        <IonSelectOption value={AgeCategory.Open}>powyżej 18 lat: Open</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem color="none">
                      {
                        formikProps.values.sex === Sex.M ? (
                          <IonSelect
                            className={styles.labels}
                            value={formikProps.values.weightCategory}
                            onIonChange={(e) => {
                              formikProps.setFieldValue("weightCategory", e.detail.value)
                            }}
                            label="Kategoria wagowa"
                            placeholder="Kategoria"
                            disabled={!formikProps.values.sex}
                          >
                            {
                              MEN_WEIGHT_CATEGORIES.map(category => (
                                <IonSelectOption value={category.value}>{category.label}</IonSelectOption>
                              ))
                            }
                          </IonSelect>
                        ) : (
                          <IonSelect
                            className={styles.labels}
                            value={formikProps.values.weightCategory}
                            onIonChange={(e) => {
                              formikProps.setFieldValue("weightCategory", e.detail.value)
                            }}
                            label="Kategoria wagowa"
                            placeholder="Kategoria"
                            disabled={!formikProps.values.sex}
                          >
                            {
                              WOMEN_WEIGHT_CATEGORIES.map(category => (
                                <IonSelectOption value={category.value}>{category.label}</IonSelectOption>
                              ))
                            }
                          </IonSelect>
                        )
                      }
                    </IonItem>
                    <IonButton disabled={Boolean(Object.keys(formikProps.errors).length) || hasNickNameError} type="submit" color="dark">Zapisz</IonButton>
                  </form>
                <DeleteAccount />
                </div>
              </IonContent>
            )}
          </Formik>
        }
        <IonAlert
          isOpen={showProfileUpdated}
          header="Aktualizacja"
          subHeader="Profil zaktualizowany!"
          buttons={['OK']}
          onDidDismiss={() => setShowProfileUpdated(false)}
        />
        <IonLoading message="Wczytywanie danych…" isOpen={isFetchingData} />
      </IonContent>
    </IonPage>
  );
};

export default Profile;
