import { IonContent } from '@ionic/react';
import styles from './AgreementsContent.module.scss'

const AgreementsContent: preact.FunctionComponent = () => {

  return (
    <IonContent className="ion-padding">
      <div className={styles.container}>
        <h2>
          polityka prywatności
          i plików cookie
          World Boxer Championship
        </h2>
        <h3>
          Obowiązująca od 1 października 2023
        </h3>

        <h4>
          kto przetwarza twoje dane osobowe
        </h4>
        <p>
          Administratorem Twoich danych osobowych, pozyskanych za pośrednictwem aplikacji mobilnej SMART BOXER i strony internetowej worldboxerchampionship.com jest Michał Świerz prowadzący działalność gospodarczą pod nazwą Gravitacja Michał Świerz z siedzibą w ul. Kosów 4, 40-541 Katowice (dalej: Administrator).<br />
          Oznacza to, że wskazany wyżej podmiot, czyli administrator danych osobowych jest odpowiedzialny za wykorzystanie Twoich danych osobowych w sposób bezpieczny i zgodny z obowiązującymi przepisami oraz przyjętymi regulacjami wewnętrznymi Administratora, to jest m.in. z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE<br />
          (ogólne rozporządzenie o ochronie danych), zwanym dalej RODO, a także niniejszą Polityką Przetwarzania Danych i Plików Cookie.
        </p>

        <h4>
          jak się z nami skontaktować
        </h4>
        <p>
          W sprawach dotyczących Twoich danych osobowych możesz się z nami skontaktować poprzez:
        </p>
        <ul>
          <li>
            adres e-mail: wbc@worldboxerchampionship.com
          </li>
          <li>
            listownie: Gravitacja Michał Świerz, ul. Kosów 4, 40 – 541 Katowice
          </li>
          <li>
            telefonicznie: +48 578 917 355
          </li>
        </ul>
        <h4>
          w jakim celu i na jakiej podstawie wykorzystujemy twoje dane osobowe
        </h4>
        <p>
          Twoje dane osobowe pozyskane w trakcie rejestracji w aplikacji mobilnej i/lub na stronie internetowej Smart Boxer oraz w trakcie korzystania z usług dostępnych za ich pośrednictwem przetwarzane są w celu:
        </p>
        <ul>
          <li>
            rejestracji w aplikacji mobilnej i/lub na stronie internetowej (założenia konta) i obsługi konta oraz korzystania ze świadczonych przez Administratora usług dostępnych przez aplikację mobilną i /lub stronę internetową, takich jak udział w rankingach i innych użytecznościach związanych z udziałem w World Boxer Championship (podstawa prawna: art. 6 ust. 1 lit. b) RODO - w zakresie danych podstawowych (zawarcie i wykonanie umowy) oraz art. 9 ust. 2 lit.a)  RODO (zgoda – w zakresie danych dot. Twojej kategorii wagowej, waga pośrednio może bowiem świadczyć o Twoim zdrowiu, a takie dane to tzw. dane wrażliwe);
          </li>
          <li>
            realizacji obowiązków prawnych ciążących na Administratorze, a wiążących się z realizacją celów, w jakich Administrator przetwarza dane osobowe (podstawa prawna - art. 6 ust. 1 lit. c) RODO);
          </li>
          <li>
            realizacji prawnie uzasadnionych interesów Administratora polegających na promowaniu jego działalności oraz oferowanych przez niego usług poprzez podejmowanie działań marketingowych, w tym w formie marketingu bezpośredniego, profilowania, a także przesyłania informacji handlowych dotyczących World Boxer Championship drogą elektroniczną (podstawa prawna - art. 6 ust. 1 lit. f) RODO);
          </li>
          <li>
            realizacji prawnie uzasadnionych interesów Administratora, wiążących się z realizacją celów wskazanych powyżej, polegających na dochodzeniu lub odpieraniu ewentualnych roszczeń (podstawa prawna - art. 6 ust. 1 lit. f) RODO).
          </li>
        </ul>
        <p>
          Dodatkowo Administrator przetwarza Twoje dane osobowe, jeżeli odwiedzisz profile Administratora prowadzone w mediach społecznościowych (w tym: Facebook, Instagram). Dane te są przetwarzane wyłącznie w związku z prowadzeniem profilu, w tym w celu informowania Użytkowników o aktywności Administratora oraz promowaniu różnego rodzaju wydarzeń, usług oraz produktów oferowanych przez Administratora, a także w celu komunikacji z użytkownikami za pośrednictwem funkcjonalności dostępnych w mediach społecznościowych (podstawa prawna - art. 6 ust. 1 lit. f) RODO.<br />
          Administrator może też gromadzić takie Twoje dane, jak dane nawigacyjne, w tym informacje o linkach i odnośnikach, w które zdecydujesz się kliknąć lub innych czynnościach, podejmowanych w aplikacji mobilnej/ i lub stronie internetowej (podstawa prawna:<br />
          art. 6 ust. 1 lit. f RODO). Dane nawigacyjne mogą być wykorzystywane w celu zapewnienia lepszej obsługi, analizy danych statystycznych i dostosowania do Twoich indywidualnych preferencji.
        </p>

        <h4>
          JAKIE DANE NALEŻY NAM PODAĆ W ZWIĄZKU Z KORZYSTANIEM Z NASZYCH USŁUG
        </h4>
        <p>
          W przypadku rejestracji konta w aplikacji mobilnej i/lub na stronie internetowej prosimy o logowanie za pośrednictwem Twojego konta Google, w związku z czym będziemy przetwarzać takie Twoje dane udostępnione w aplikacji Google jak:
        </p>
        <ul>
          <li>
            imię i nazwisko i/lub nick;
          </li>
          <li>
            zdjęcie/avatar;
          </li>
          <li>
            adres e-mail;
          </li>
          <li>
            telefon.
          </li>
        </ul>
        <p>
          W przypadku korzystania z usług dostępnych przez aplikację mobilną i/lub stronę internetową dodatkowo możemy poprosić Cię o takie dane jak:
        </p>
        <ul>
          <li>
            imię i nazwisko (jeśli nie podano przy rejestracji);
          </li>
          <li>
            lokalizacja;
          </li>
          <li>
            dane adresowe:
            <ul>
              <li>
                kod pocztowy, miejscowość,
              </li>
              <li>
                kraj (państwo),
              </li>
              <li>
                ulica, numer domu lub mieszkania,
              </li>
            </ul>
          </li>
          <li>
            dane karty płatniczej/kredytowej.
          </li>
        </ul>
        <p>
          Podczas korzystania z aplikacji mobilnej i/lub strony internetowej  mogą być pobierane dodatkowe informacje, w szczególności: adres IP przypisany do telefonu/ komputera Usługobiorcy lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.
        </p>
        <h4>
          uprawnienia związane z przetwarzaniem danych osobowych
        </h4>
        <p>
          W związku z przetwarzaniem przez Administratora danych osobowych możesz złożyć wniosek o:
        </p>
        <ul>
          <li>udzielenie dostępu do Twoich danych (art.15 RODO);</li>
          <li>sprostowanie Twoich danych (art.16 RODO);</li>
          <li>usunięcie Twoich danych, czyli realizację tzw. „prawa do bycia zapomnianym" (art. 17 RODO), o ile przepisy prawa nie nakładają na nas obowiązku dalszego ich przetwarzania przez określony czas;</li>
          <li>ograniczenie przetwarzania (art.18 RODO), o ile przepisy prawa nie nakładają na nas obowiązku dalszego przetwarzania tych danych przez określony czas;</li>
          <li>przeniesienia danych do innego administratora lub do Ciebie (art. 20 RODO) – o ile będzie to technicznie możliwe oraz przetwarzanie odbywa się na podstawie zgody i/lub w sposób zautomatyzowany;</li>
        </ul>
        <p>
          Ponadto masz prawo do:
        </p>
        <ul>
          <li>złożenia sprzeciwu (art. 21 RODO) wobec przetwarzania Twoich danych osobowych przetwarzanych w celu marketingu bezpośredniego oraz profilowania. Po wniesieniu sprzeciwu nie wolno nam przetwarzać Twoich  danych osobowych we wskazanym celu, chyba że wykażemy istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec Twoich interesów, praw i wolności;</li>
          <li>cofnięcia udzielonej zgody na przetwarzanie danych osobowych – w każdym czasie; cofnięcie zgody ma skutek od momentu jej wycofania, nie wpływa jednak na przetwarzanie dokonywane zgodnie z prawem przed jej cofnięciem.</li>
          <li>wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, w zakresie naruszenia jego praw do ochrony danych osobowych lub innych praw przyznanych na mocy RODO.</li>
        </ul>

        <h4>
          dobrowolność podania danych
        </h4>
        <p>
          Podanie przez Ciebie danych osobowych jest dobrowolne, lecz niezbędne do realizacji celów, w jakich dane są przetwarzane. W przypadku niepodania danych realizacja założonych celów przetwarzania, jak założenia konta w aplikacji mobilnej i/lub stronie internetowej oraz korzystanie usług dostępnych za ich pośrednictwem nie będzie możliwe.
        </p>

        <h4>
          udostępnianie danych innym podmiotom
        </h4>
        <p>
          Twoje dane osobowe przekazywane są dostawcom usług, z których korzysta Administrator w związku z realizacją celów przetwarzania.<br />
          Należą do nich m.in. dostawcy świadczący usługi hostingu, usługi księgowe, dostarczający systemy do marketingu, systemy do analizy ruchu na stronie internetowej, systemy do analizy skuteczności kampanii marketingowych i obsługi marketingowej, podmioty świadczące usługi płatności elektronicznych oraz bankowe, a także firmy kurierskie lub brokerzy kurierscy.<br />
          Dostawcy usług, którym przekazywane są dane osobowe, w zależności od uzgodnień umownych i okoliczności, albo podlegają poleceniom Administratora co do celów i sposobów przetwarzania tych danych (podmioty przetwarzające dane na polecenie Administratora) albo samodzielnie określają cele i sposoby ich przetwarzania (odrębni administratorzy danych).<br />
          Dodatkowo, za wyrażoną uprzednio zgodą przekazujemy Twoje dane osobowe naszym partnerom, w tym fundatorom nagród.<br />
          W przypadku skierowania żądania, Administrator udostępnia również dane osobowe uprawnionym organom państwowym, w szczególności jednostkom organizacyjnym Prokuratury, Policji, Prezesowi Urzędu Ochrony Danych Osobowych, Prezesowi Urzędu Ochrony Konkurencji i Konsumentów lub Prezesowi Urzędu Komunikacji Elektronicznej.
        </p>

        <h4>
          udostępnienie danych do państwa trzeciego
        </h4>
        <p>
          Twoje dane mogą być przekazywane poza obszar Unii Europejskiej – do państw trzecich.<br />
          Będą one przekazywane wyłącznie odbiorcom, którzy gwarantują najwyższą ochronę i bezpieczeństwo danych, m.in. poprzez:
        </p>
        <ul>
          <li>współpracę z podmiotami przetwarzającymi dane osobowe w państwach, w odniesieniu do których została wydana stosowna decyzja Komisji Europejskiej,</li>
          <li>stosowanie standardowych klauzul umownych wydanych przez Komisję Europejską (tak jak m to miejsce np. w przypadku Google), </li>
          <li>stosowanie wiążących reguł korporacyjnych zatwierdzonych przez właściwy organ nadzorczy, </li>
        </ul>
        <p>
          lub tym, na przekazywanie danych osobowych których, Użytkownik wyraził zgodę.<br />
          W związku z faktem, że Administrator korzysta z zewnętrznych dostawców różnych usług np. Facebook, Google Inc, Instagram Inc itp., Twoje dane mogą być przekazywane do Stanów Zjednoczonych Ameryki (USA) w związku z ich przechowywaniem na amerykańskich serwerach (w całości lub częściowo). Podmioty te stosują mechanizmy zgodności przewidziane przez RODO (np. certyfikaty) lub standardowe klauzule umowne w odniesieniu do swoich usług. <br />
          Szczegółowe informacje dostępne są w treści polityki prywatności każdego z dostawców tych usług, dostępnych na ich stronach internetowych.<br />
        </p>

        <h4>
          przetwarzanie danych w sposób zautomatyzowany lub w formie profilowania
        </h4>
        <p>
          W celu realizowania działań marketingowych związanych z marketingiem bezpośrednim Administrator może korzystać z profilowania.  Profilowanie oznacza to, że dzięki automatycznemu przetwarzaniu danych Administrator dokonuje oceny wybranych czynników dotyczących Twoich decyzji w zakresie z korzystania z usług dostępnych za pośrednictwem aplikacji mobilnej i lub strony internetowej w celu ich analizy i stworzenia prognozy Twoich preferencji na przyszłość. Skutkiem profilowania przez Administratora Twoich danych może być przyznanie Ci rabatu i/lub zaproponowanie usług odpowiadającym Twoim preferencjom. Mimo profilowania to Ty podejmujesz decyzję, czy skorzystać z przedstawionej propozycji, czy też nie. Ponadto decyzje podejmowane przez Administratora w oparciu o profilowanie nigdy nie dotyczą zawarcia umowy lub odmowy świadczenie przez Administaratora usług.
        </p>

        <h4>
          okres, przez jaki dane będą przetwarzane
        </h4>
        <p>
          Twoje dane osobowe będą przetwarzane przez okres niezbędny do realizacji celów przetwarzania.<br />
          W przypadku, gdy podstawą przetwarzania danych osobowych jest zgoda wówczas dane osobowe przetwarzane są przez Administratora tak długo, aż zgoda nie zostanie odwołana.<br />
          W przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy (to jest czas korzystania z aplikacji mobilnej i/lub strony internetowej i dostępnych za ich pośrednictwem usług, wówczas dane osobowe przetwarzane są przez Administratora tak długo, jak jest to niezbędne do korzystania z tych produktów, czyli do czasu usunięcia przez Ciebie konta - a po tym czasie ewentualnie przez okres odpowiadający okresowi przedawnienia roszczeń, realizacji roszczeń z tytułu rękojmi lub gwarancji oraz przez okres, przez jaki musi być przechowywana dokumentacja podatkowa, o ile jest z tym związana konieczność przetwarzania Twoich danych.
        </p>
        <h4>
          pliki cookies, adres ip
        </h4>
        <p>
          Administrator w ramach prowadzonej strony internetowej używa niewielkich plików, zwanych cookies. Zapisywane są one przez Administratora na urządzeniu końcowym osoby odwiedzającej stronę internetową, jeżeli przeglądarka internetowa na to pozwala. Plik cookie zwykle zawiera nazwę domeny, z której pochodzi, swój „czas wygaśnięcia" oraz indywidualną, losowo wybraną liczbę identyfikującą ten plik.<br />
          Administrator może wykorzystywać pliki cookies:
        </p>
        <ul>
          <li>
            sesyjne: po zakończeniu sesji danej przeglądarki lub wyłączeniu komputera zapisane informacje są usuwane z pamięci urządzenia. Mechanizm cookies sesyjnych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Twojego urządzenia.
          </li>
          <li>
            trwałe: są przechowywane w pamięci Twojego urządzenia i pozostają tam do momentu ich skasowania lub wygaśnięcia. Mechanizm cookies trwałych nie pozwala na pobieranie jakichkolwiek danych osobowych ani żadnych informacji poufnych z Twojego urządzenia.
          </li>
        </ul>
        <p>
          Administrator może wykorzystywać pliki cookies własne w celu:
        </p>
        <ul>
          <li>
            uwierzytelniania Cię na stronie internetowej i zapewnienia ciągłości sesji (po zalogowaniu), dzięki czemu nie musisz wpisywać ponownie na kolejnych podstronach  loginu i hasła;
          </li>
          <li>
            analiz i badań oraz audytu oglądalności, a w szczególności do tworzenia anonimowych statystyk, które pomagają zrozumieć, w jaki sposób korzystacie ze strony internetowej, co umożliwia ulepszanie jej struktury i zawartości.
          </li>
        </ul>
        <p>
          Administrator może wykorzystywać pliki cookies zewnętrzne w celu:
        </p>
        <ul>
          <li>popularyzacji strony internetowej za pomocą serwisu społecznościowego facebook.com (administrator cookies zewnętrznego: Facebook Inc z siedzibą w USA lub Facebook Ireland z siedzibą w Irlandii), </li>
          <li>popularyzacji strony internetowej za pomocą serwisu społecznościowego instagram.com (administrator cookies zewnętrznego: Instagram Inc z siedzibą w USA), </li>
          <li>zbierania ogólnych i anonimowych danych statycznych za pośrednictwem narzędzi Google Analytics (administrator cookies zewnętrznego: Google Inc z siedzibą w USA).</li>
        </ul>
        <p>
          Mechanizm cookies jest bezpieczny dla Twojego urządzenia. W szczególności tą drogą nie jest możliwe przedostanie się do Twojego urządzenia wirusów lub innego niechcianego oprogramowania lub oprogramowania złośliwego. Niemniej w swoich przeglądarkach masz możliwość ograniczenia lub wyłączenia dostępu plików cookies do Twojego urządzenia. W przypadku skorzystania z tej opcji korzystanie ze strony internetowej będzie możliwe, poza funkcjami, które ze swojej natury wymagają plików cookies.<br />
          Administrator może gromadzić adresy IP. Adres IP to numer przydzielany komputerowi osoby odwiedzającej stronę internetową przez dostawcę usług internetowych. Numer IP umożliwia dostęp do Internetu. W większości przypadków jest przypisywany komputerowi dynamicznie, tj. zmienia się przy każdym połączeniu z Internetem. Adres IP jest wykorzystywany przez Administratora przy diagnozowaniu problemów technicznych z serwerem, tworzeniu analiz statystycznych (np. określeniu, z jakich regionów notujemy najwięcej odwiedzin), jako informacja przydatna przy administrowaniu i udoskonalaniu strony internetowej, a także w celach bezpieczeństwa oraz ewentualnej identyfikacji obciążających serwer, niepożądanych automatycznych programów do przeglądania treści strony internetowej.<br />
          Strona internetowa zawiera linki i odnośniki do innych stron internetowych. Administrator nie ponosi odpowiedzialności za zasady ochrony prywatności na nich obowiązujących.
        </p>

        <h4>
          dostęp do twojego urządzenia
        </h4>
        <p>
          Nasza aplikacja mobilna i/lub strona uzyskuje dostęp do takich funkcjonalności Twojego urządzenia jak:
        </p>
        <ul>
          <li>aparat fotograficzny (w celu umożliwienia skanowania kodów QR oraz robienia zdjęć profilowych);</li>
          <li>galeria zdjęć (w celu umożliwienia dodawania zdjęć profilowych);</li>
          <li>funkcjonalności związane z lokalizacją;</li>
          <li>czytnik linii papilarnych lub system rozpoznawania twarzy (te dane, umożliwiające logowanie nie są przesyłane do systemu Administratora i są przechowywane wyłącznie przez system operacyjny Twojego urządzenia. Jako Administrator dostajemy wyłącznie informację co do wybranego przez Ciebie sposobu logowania.</li>
        </ul>

        <h4>
          zarządzanie bezpieczeństwem
        </h4>
        <p>
          Administrator zapewnia Ci bezpieczne i szyfrowane połączenie podczas przesyłania danych osobowych oraz podczas rejestracji i logowania się do Twojego konta poprzez aplikację mobilną i/lub stronę internetową. Administrator stosuje certyfikat SSL wystawiony przez Nazwa.pl w zakresie bezpieczeństwa i szyfrowania przesyłanych danych przez sieć Internet.<br />
          Administrator nigdy nie wysyła żadnej korespondencji, w tym korespondencji elektronicznej z prośbą o podanie danych do logowania, a w szczególności hasła dostępowego do Twojego konta.
        </p>
      </div>
    </IonContent>
  )
};

export default AgreementsContent;
