import { useEffect } from 'preact/hooks';
import { useHistory } from 'react-router'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { useUserStore } from '../store/userStore';
import { ROUTES } from '../navigation';

// TODO: Move to pages/Logout
const Logout: preact.FunctionComponent = () => {
  const logout = useUserStore((state) => state.logout);
  const history = useHistory();

  useEffect(() => {
    logout();
    GoogleAuth.signOut()

    history.push(ROUTES.GAME_START);
  }, []);

  return null;
};

export default Logout;
