/**
 * Fetch event source url with head request to test for response status code
 */
export async function fetchEventSourceHead(url: URL): Promise<Response | undefined> {
  try {
    return await fetch(url, {
      method: 'HEAD',
      headers: {
        'Accept': 'text/event-stream',
        'Cache-Control': 'no-cache',
      },
      credentials: 'include',
    })
  } catch { }
}
