import { useRef, useState } from 'preact/hooks';
import { useHistory } from 'react-router'
import {IonInput, IonText, useIonViewDidEnter, useIonViewWillLeave, useIonToast } from '@ionic/react';
import { ROUTES } from '../../navigation';
import { useGameSessionStore } from '../../store/gameSessionStore';
import { ERROR_CODES } from '../../utils/Error';

import styles from './PinCode.module.scss';

const MAX_INPUT_LENGTH = 4;

const PinCode: preact.FunctionComponent = () => {
  const login = useGameSessionStore((state) => state.login);
  const history = useHistory();
  const [pinCode, setPinCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLIonInputElement>(null)

  const [presentToast] = useIonToast()

  /**
   * Ionic recommended way to set autofocus
   * @link https://ionicframework.com/docs/api/input#setfocus
   *
   * @note Focusing on inputs on iOS casuses non-fatal error:
   *       API error: <_UIKBCompatInputView: 0x13df203e0; frame = (0 0; 0 0); layer = <CALayer: 0x6000039750a0>> returned 0 width, assuming UIViewNoIntrinsicMetric
   * @link https://github.com/ionic-team/capacitor-plugins/issues/580
   * @link https://github.com/ionic-team/capacitor/issues/835
   */
  useIonViewDidEnter(() => {
    inputRef.current?.setFocus()
  })

  /**
   * Unfocus element on exit (to work around iOS quirk)
   */
  useIonViewWillLeave(() => {
    inputRef.current?.getInputElement()
      .then(inputElement => inputElement.blur())
  })

  const onSubmit = async () => {
    try {
      await login(pinCode);

      presentToast({
        message: 'Zalogowałeś się do gry',
        duration: 5_000,
      })

      history.push(ROUTES.GAME_START);
      setPinCode('');
    } catch(e: any) {
      setPinCode('');
      if (e.code === ERROR_CODES.BAD_REQUEST) {
        setError(`Błąd połączenia z serwerem (${ERROR_CODES.BAD_REQUEST})`)
      }
      if (e.code === ERROR_CODES.NOT_FOUND) {
        setError(`Błędny token (${ERROR_CODES.NOT_FOUND})`);
      }
    }
  }

  const onInputChange = (e: any) => {
    setPinCode(e.target.value);
    setError(null);
  }

  const onKeyUp = () => {
    if (pinCode.length === MAX_INPUT_LENGTH) {
      onSubmit();
    }
  }

  return (
    <div className={styles.container}>
      <IonText class="ion-text-center ion-margin">
        Po zalogowaniu wyniki gier będą przypisywane do Twojego konta
      </IonText>
      <div className={`${styles.pinCode} ion-margin`}>
        <IonInput
          aria-label="Pin Code"
          className={styles.input}
          inputmode="numeric"
          fill="outline"
          maxlength={MAX_INPUT_LENGTH}
          mode="md"
          pattern={`\\d{${MAX_INPUT_LENGTH}}`}
          ref={inputRef}
          required
          value={pinCode}
          type="text"
          onIonInput={onInputChange}
          onKeyUp={onKeyUp}
        />
      </div>
      {
        error && (
          <IonText class={`${styles.error} ion-text-center ion-margin`}>
            <h5>{error}</h5>
          </IonText>
        )
      }
    </div>
  )
};

export default PinCode;
