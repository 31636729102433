const ROUTES = {
  AGREEMENTS: '/agreements',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PINCODE: '/pincode',
  SESSION: '/session',
  PROFILE: '/profile',
  GAME_START: '/game-start',
  GAME_RANKING: '/game-ranking',
  LOGOUT: '/logout',
} as const

export default ROUTES
