import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { fetcher } from '../utils/fetcher'

type UserScore = {
  max: number,
  avg: number,
  player: {
    id: number,
    nickName: string
  }
}

interface IScoresState {
  data: UserScore[] | null,
  getScores: (startDate: Date, endDate: Date) => Promise<void>,
  sortByAverage: () => void,
  sortByMax: () => void,
}

export const useScoresStore = create<IScoresState>()(devtools((set) => ({
  data: null,
  getScores: async (startDate: Date, endDate: Date) => {
    return fetcher(`game/scores?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
      .then(data => set({ data }))
  },
  sortByAverage: () => set((state: IScoresState) => {
    const sortedByAverage = state?.data?.sort((a: UserScore, b: UserScore) => {
      return b.avg - a.avg;
    })
    return {
      data: sortedByAverage
    }
  }),
  sortByMax: () => set((state: IScoresState) => {
    const sortedByMax = state?.data?.sort((a: UserScore, b: UserScore) => {
      return b.max - a.max;
    })
    return {
      data: sortedByMax
    }
  })
}), { store: 'ScoresStore' }));
