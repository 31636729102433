import { useState } from 'preact/hooks';
import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton } from '@ionic/react';
import AgreementsContent from '../AgreementsContent/AgreementsContent';

import styles from './AggementsModal.module.scss';

const AgreementsModal: preact.FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="ion-margin-horizontal ion-text-center">
      <p className={styles.textLarge}>
        Po zalogowaniu wyniki gier będą przypisywane do Twojego konta
      </p>
      <p>
        Logując się akceptujesz&nbsp;
        <a onClick={() => setIsModalOpen(true)}>politykę prywatności</a>
      </p>
      <IonModal isOpen={isModalOpen}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Polityka prywatności</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setIsModalOpen(false)}>Zamknij</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <AgreementsContent />
      </IonModal>
    </div>
  )
};

export default AgreementsModal;
