import {
  IonContent,
  IonGrid,
  IonRow,
  IonPage,
  isPlatform,
} from '@ionic/react';

import GoogleLogin from '../../components/GoogleLogin/GoogleLogin';
import AppleSignIn from '../../components/AppleSignIn/AppleSignIn';
import AgreementsModal from '../../components/AgreementsModal/AgreementsModal';
import logoSvg from '../../assets/logo.svg';

import styles from './Login.module.scss'

/**
 * Prioritize AppleSignIn on iOS devices
 * Prioritize GoogleLogin on Web platform
 * Do not use AppleSignIn on Android platform
 * @see https://github.com/capacitor-community/apple-sign-in/issues/13
 */
const authenticationServices: JSX.Element[] =
  isPlatform('ios') && isPlatform('capacitor')
    ? [<AppleSignIn />, <GoogleLogin />]
    : [<GoogleLogin />]
  /*
  // TODO: Enable AppleSignIn when Service is configured with Developer account
  isPlatform('ios')
    // iOS
    ? [<AppleSignIn />, <GoogleLogin />]
    : isPlatform('capacitor')
      // Android native
      ? [<GoogleLogin />]
      // Web on Android
      : [<GoogleLogin />, <AppleSignIn />]
  */

const Login: preact.FunctionComponent = () => {
  return (
    <IonPage>
      <IonContent className={styles.content} forceOverscroll={false}>
        <div className={styles.loginScreen}>
          <img src={logoSvg} alt="logo" width="160" height="160" />
          <IonGrid className={styles.grid}>
            {authenticationServices.map((authenticationService, index) =>
              <IonRow key={index} class="ion-justify-content-center ion-margin-bottom">
                {authenticationService}
              </IonRow>
            )}
            <IonRow class="ion-justify-content-center">
              <AgreementsModal />
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
