import { create } from "zustand"
import { devtools } from "zustand/middleware"

import { fetcher } from '../utils/fetcher'

const API_URL = import.meta.env.VITE_API_URL;

export type GameScore = number

interface GameSession {
  login: (code: string) => Promise<any>,

  sessionScore: Array<{ value:  number }> | null,
  setSessionScore: (sessionScore: Array<{ value:  number }> | null) => void,
  resetSessionScore: () => void
}

export const useGameSessionStore = create<GameSession>()(devtools((set) => ({
  login: (code: string) => {
    return fetcher(`${API_URL}/game-session/player/sign-in`, {
      method: 'POST',
      body: JSON.stringify({ token: code }),
    })
  },

  sessionScore: null,
  setSessionScore: (sessionScore) => set({ sessionScore }),
  resetSessionScore: () => set({ sessionScore: null })
}), { store: 'GameSessionStore' }));
