import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
  isPlatform,
} from '@ionic/react'
import { logOut, documentLock, reader } from 'ionicons/icons';

import contact from '../../assets/contact.svg';
import gloves from '../../assets/gloves.svg';
import man from '../../assets/man.svg';
import people from '../../assets/people.svg';
import pig from '../../assets/pig.svg';
import search from '../../assets/search.svg';
import trophy from '../../assets/trophy.svg';
import warning from '../../assets/warning.svg';
import closeIcon from '../../assets/close-outline.svg';

import styles from './Menu.module.scss';

import { ROUTES } from '../../navigation';

type MenuItem = {
  title: string,
  url: string,
  icon: string,
  disabled?: boolean,
}

const menuItems: MenuItem[] = [
  { title: 'Znajdź boksera', url: '/', icon: search, disabled: true },
  { title: 'Ranking', url: ROUTES.GAME_RANKING, icon: trophy },
  { title: 'Profil', url: ROUTES.PROFILE, icon: man },
  { title: 'Turnieje / Wyzwania', url: '/', icon: gloves, disabled: true},
  { title: 'Doładuj konto', url: '/', icon: pig, disabled: true },
  { title: 'Odbierz nagrodę', url: '/', icon: trophy, disabled: true },
  { title: 'Drużyny', url: '/', icon: people, disabled: true },
  { title: 'Zgłoś problem', url: '/', icon: warning, disabled: true },
  { title: 'Polityka prywatności', url: ROUTES.AGREEMENTS, icon: documentLock },
  { title: 'Regulamin', url: ROUTES.TERMS_AND_CONDITIONS, icon: reader },
  { title: 'Kontakt', url: '/', icon: contact, disabled: true},
  { title: 'Wyloguj się', url: ROUTES.LOGOUT, icon: logOut },
];

// Hide disabled menu items on iOS (Apple App Store requirement)
const availableMenuItems: MenuItem[] = isPlatform('ios')
  ? menuItems.filter(menuItem => !menuItem.disabled)
  : menuItems

const Menu: preact.FunctionComponent = () => {
  return (
    <IonMenu
      contentId="main"
      side="end"
    >
      <IonHeader className="ion-no-border ion-margin-bottom">
        <IonToolbar>
          <IonButtons slot="end">
            <IonMenuToggle>
              <IonButton className={styles.closeButton}>
                <IonIcon
                  className={styles.closeIcon}
                  icon={closeIcon}
                />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          {availableMenuItems.map((menuItem, index) =>
            <IonMenuToggle
              key={index}
            >
              <IonItem
                disabled={menuItem.disabled}
                routerLink={menuItem.url}
                routerDirection="none"
                detail={false}
              >
                <IonIcon
                  className={styles.menuItemIcon}
                  slot="start"
                  color="dark"
                  icon={menuItem.icon}
                />
                <IonLabel className={styles.menuItem}>
                  {menuItem.title}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default Menu;
