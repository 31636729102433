/**
 * Ionic Docs > [React Lifecycle]{@link https://ionicframework.com/docs/react/lifecycle}
 * Hooks require IonPage component
 */
import { useEffect, useState } from 'preact/hooks'
import type { LifeCycleCallback } from '@ionic/react'
import {
  useIonViewWillEnter,
  useIonViewDidLeave
} from '@ionic/react'

/**
 * The useEffect alternative for Ionic
 */
export const useIonViewEffect = (effectCallback: LifeCycleCallback, deps: any[] = []): void => {
  const isVisible = useIonViewVisibility()

  // Execute lifecycle callback every time page visiblity or depependencies change
  useEffect(() => {
    return isVisible ? effectCallback() : undefined
  }, [isVisible, ...deps])
}

/**
 * Returns true when page will enter, false when page did leave.
 * @note Even that useIonView* hooks accept deps, lifecycle callbacks are not re-run on change
 *       See https://github.com/ionic-team/ionic-framework/issues/21792
 */
export const useIonViewVisibility = (): boolean => {
  const [ isVisible, setIsVisible ] = useState<boolean>(false)

  useIonViewWillEnter(() => setIsVisible(true))
  useIonViewDidLeave(() => setIsVisible(false))

  return isVisible
}
