import { IonContent, IonPage } from '@ionic/react';
import MenuHeader from '../components/MenuHeader/MenuHeader';
import PinCode from '../components/PinCode/PinCode';

const PinCodePage: preact.FunctionComponent = () => {
  return (
    <IonPage>
      <MenuHeader />
      <IonContent>
        <PinCode />
      </IonContent>
    </IonPage>
  );
};

export default PinCodePage;
