import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect, useState, useCallback, useRef } from 'preact/hooks';
import { App as CapacitorApp } from '@capacitor/app';
import { ROUTES } from './navigation';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import PreferencesProvider from './components/PreferencesProvider/PreferencesProvider';
import PrivateRoute from './components/PrivateRoute';
import Menu from './components/Menu/Menu';
import Logout from './components/Logout';
import NativeHandlers from './components/NativeHandlers/NativeHandlers';
import LaunchQueueHandler from './components/LaunchQueueHandler';
import NotFound from './pages/NotFound/NotFound';

import SessionReader from './pages/SessionReader/SessionReader';
import GameStart from './pages/GameStart/GameStart';
import PinCode from './pages/PinCode';
import Profile from './pages/Profile/Profile';
import Rankings from './pages/Rankings';
import Agreements from './pages/Agreements/Agreements';
import TermsAndConditions from './pages/TermsAndConditions';

import { useGameSessionStore } from './store/gameSessionStore';
import { useUserStore } from './store/userStore';
import { useAuthStore } from './store/authStore';

import { fetchEventSourceHead } from './utils/eventSource';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/theme.css';

import SplashScreen from './pages/SplashScreen/SplashScreen'

import './fonts/tank.css';

import styles from './App.module.scss'

setupIonicReact();

const App: preact.FunctionComponent = () => {
  const setSessionScore = useGameSessionStore((state => state.setSessionScore));
  const { userData } = useUserStore(
    (state) => ({
      userData: state.data
    }),
  )
  const accessToken = useAuthStore((state => state.accessToken))
  const [isAppPaused, setIsAppPaused] = useState<boolean>(false)
  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const [, setEventSourceReadyState] = useState<number | null>(null)

  useEffect(() => {
    CapacitorApp.addListener('pause', () => setIsAppPaused(true))
    CapacitorApp.addListener('resume', () => setIsAppPaused(false))

    return () => CapacitorApp.removeAllListeners()
  }, [])

  const lastMessageEventRef = useRef<MessageEvent>()

  // Create new event source
  const createEventSource: (url: string | URL) => Promise<void> = useCallback(
    async (url) => {
      // Clone
      url = new URL(url)

      // Append lastEventId to url query
      if (lastMessageEventRef.current) {
        url.searchParams.set('last-event-id', lastMessageEventRef.current.lastEventId)
      }

      // Test
      const response = await fetchEventSourceHead(url)

      // Client error (non-retriable)
      if (response && response.status >= 400 && response.status < 500 && response.status !== 429) {
        return setEventSourceReadyState(EventSource.CLOSED)
      }

      setEventSource(new EventSource(url, { withCredentials: true }))
    },
    [lastMessageEventRef.current?.lastEventId]
  )

  // Create new event source
  useEffect(() => {
    if (!userData || !accessToken) {
      return
    }

    const url = new URL(`player/${userData.id}/events`, import.meta.env.VITE_API_URL)

    url.searchParams.set('access-token', accessToken)
    url.searchParams.set('ts', Date.now().toFixed())

    createEventSource(url)
  }, [userData?.id, accessToken])

  // Handle event source events
  useEffect(() => {
    if (!eventSource) {
      return
    }

    let reconnectOnErrorTimeoutId: number | undefined = undefined

    // Connecting
    setEventSourceReadyState(eventSource.readyState)

    // Handle open
    eventSource.addEventListener('open', (_openEvent) => {
      setEventSourceReadyState(eventSource.readyState)
    })

    // Handle network (connecting) or server error (closed)
    eventSource.addEventListener('error', (_errorEvent) => {
      // Because we'll try to reconnect
      setEventSourceReadyState(EventSource.CONNECTING)

      // Reconnection on network error, no-op
      if (eventSource.readyState === EventSource.CONNECTING) {
        return
      }

      // Fatal error: Non-200 response status, request blocked by CORS policy or network reques timeout (90s+)
      reconnectOnErrorTimeoutId = window.setTimeout(() => createEventSource(eventSource.url), 15_000)
    })

    // Stream
    eventSource.addEventListener('player/stream/start', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent
    })

    // Game credits
    eventSource.addEventListener('player/game/credits', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent
    })

    // Game Start event
    eventSource.addEventListener('player/game/start', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent
    })

    // Session end event
    eventSource.addEventListener('player/session/end', (messageEvent: MessageEvent<string>) => {
      lastMessageEventRef.current = messageEvent
      const data = JSON.parse(messageEvent.data)

      setSessionScore(data)
    })

    // Close event source for given serial number
    return () => {
      eventSource.close()
      window.clearTimeout(reconnectOnErrorTimeoutId)
    }
  }, [eventSource])

  return (
    <IonApp className={`bx-app ${isAppPaused ? styles.appPaused : '' }`}>
      <ErrorBoundary envMode={import.meta.env.MODE}>
        <PreferencesProvider fallback={<SplashScreen />}>

          <IonReactRouter>
            <Menu />

            <IonRouterOutlet id="main">
              <Redirect exact path="/" to={ROUTES.GAME_START} />

              <Route path={ROUTES.AGREEMENTS} component={Agreements} />

              <PrivateRoute path={ROUTES.PINCODE} component={PinCode} />
              <PrivateRoute path={ROUTES.SESSION} component={SessionReader} />

              <PrivateRoute path={ROUTES.PROFILE} component={Profile} />

              <PrivateRoute path={ROUTES.GAME_START} component={GameStart} />

              <PrivateRoute path={ROUTES.GAME_RANKING} component={Rankings} />
              <PrivateRoute path={ROUTES.LOGOUT} component={Logout} />

              <PrivateRoute path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndConditions} />

              <Route component={NotFound}/>
            </IonRouterOutlet>

            <LaunchQueueHandler />
            <NativeHandlers />
          </IonReactRouter>
        </PreferencesProvider>
      </ErrorBoundary>
    </IonApp>
  )
}

export default App;
