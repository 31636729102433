import TransferError from './TransferError'

/**
 * TypeError: Failed to fetch
 */
export default class ConnectError extends TransferError {
  public name = 'ConnectError'

  /**
   * @inheritdoc
   */
  constructor(
    request: Request,
    options?: ErrorOptions,
  ) {
    super(request, 'No connection', options)

    Object.setPrototypeOf(this, ConnectError.prototype)
  }
}
