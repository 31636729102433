import { useState, useEffect, useMemo, useRef } from 'preact/hooks';
import { IonModal, IonContent, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton } from '@ionic/react';
import { Haptics } from '@capacitor/haptics';
import { useGameSessionStore } from '../../store/gameSessionStore';
import styles from './SessionResultsModal.module.scss';

const SessionResultModal: preact.FunctionComponent = () => {
  const { sessionScore, resetSessionScore } = useGameSessionStore((state) => ({
    sessionScore: state.sessionScore,
    resetSessionScore: state.resetSessionScore
  }));
  const sortedSessionScore = useMemo(() => sessionScore?.sort((scoreA, scoreB) => scoreB.value - scoreA.value), [sessionScore])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const delayTimerRef = useRef<ReturnType<typeof setTimeout>>()
  const closeModalTimerRef = useRef<ReturnType<typeof setTimeout>>()

  const onModalClose = () => {
    setIsModalOpen(false);
    clearTimeout(delayTimerRef.current)
    clearTimeout(closeModalTimerRef.current)
    resetSessionScore();
  }

  useEffect(() => {
    const hasSessionScore = Boolean(sessionScore?.length)
    if (hasSessionScore && !isModalOpen) {
      // Delay to open results at same time as in Terminal app
      delayTimerRef.current = setTimeout(() =>  {
        setIsModalOpen(true)
        Haptics.vibrate({ duration: 700 });
      }, 10000)
    }

    if (isModalOpen) {
      closeModalTimerRef.current = setTimeout(() => {
        onModalClose()
      }, 15000);
    }
  }, [sessionScore, isModalOpen]);

  useEffect(() => {
    return () => onModalClose()
  }, [])

  return (
    <IonModal isOpen={isModalOpen}>
      <IonHeader>
        <IonToolbar className={styles.header}>
            <IonTitle size='large'>Wyniki Twojej gry</IonTitle>
            <IonButtons slot="end">
              <IonButton className={styles.headerClose} onClick={onModalClose}>Zamknij</IonButton>
            </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent >
        <section>
          {sortedSessionScore && (
            <table className={styles.bxResultsTable}>
              <tbody className={styles.bxResultsTableBody}>
                {sortedSessionScore.map(({ value }, index) => (
                  <tr className={styles.bxResultsTableRow} key={index}>
                    <td className={`${styles.bxResultsTableValue} index-${index}`}>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>
      </IonContent>
    </IonModal>
  )
};

export default SessionResultModal;
