import { useEffect } from 'preact/hooks'
import { useHistory, matchPath } from 'react-router'
import { App } from '@capacitor/app'

import { ROUTES } from '../navigation'

// White list routes for Launch queue handler/ Deep links
const allowRoutes: string[] = [
  ROUTES.SESSION,
]

/**
 * Launch Queue handler
 * App Links (Android) / Universal links (iOS) handler
 *
 * Use as a child of <IonReactRouter> component
 * @link https://developer.chrome.com/docs/web-platform/launch-handler/
 * @link https://capacitorjs.com/docs/guides/deep-links#react
 * @link https://devdactic.com/setup-deep-links-capacitor
 */
const LaunchQueueHandler: preact.FunctionComponent = () => {
  const history = useHistory()

  // Register Launch Queue consumer
  useEffect(() => {
    // Web
    window.launchQueue?.setConsumer(launchConsumer)

    // Native
    App.addListener('appUrlOpen', (event) =>
      launchConsumer({ targetURL: event.url, files: [] })
    )

    function launchConsumer(launchParams: LaunchParams) {
      if (!launchParams.targetURL) {
        return
      }

      const targetUrl = new URL(launchParams.targetURL)

      // Validate such route is defined and whitelisted
      const matchedRoute = allowRoutes.find(route =>
        matchPath(targetUrl.pathname, { path: route, strict: true, exact: true })
      )

      if (matchedRoute) {
        // Note: Must use either plain LocationDescriptor object or a string (URL instance won't work)
        history.push({
          pathname: targetUrl.pathname,
          search: targetUrl.search,
          hash: targetUrl.hash,
        })
      }

      // If no match, do nothing - let regular routing logic take over
    }
  }, [])

  return null
}

export default LaunchQueueHandler
