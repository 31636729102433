import { Route } from 'react-router-dom';
import Login from '../pages/Login/Login';
import { useEffect, useCallback } from 'preact/hooks';
import { useAuthStore } from '../store/authStore';
import { useUserStore } from '../store/userStore';

type PrivateRouteProps = {
  component: preact.ComponentType<any>,
  path: string,
}

const PrivateRoute: preact.FunctionComponent<PrivateRouteProps> = ({ component: Component, path, ...rest }: any) => {
  const { data, getUserData, isFetchingData } = useUserStore(
    (state) => ({
    data: state.data,
    isFetchingData: state.isFetchingData,
    getUserData: state.getUserData
  }));

  const accessToken = useAuthStore((state => state.accessToken))

  const shouldUserLogin = useCallback(async () => {
    if (accessToken && !data) {
      getUserData();
    }
  }, [data, accessToken])

  useEffect(() => {
    shouldUserLogin();
  }, []);

  return (
    <Route
      path={path}
      render={(props) => (
        (data || isFetchingData) ? (
          <Component {...props} />
        ) :
        (
          <Login />
        )
      )}
      {...rest}
    />
  );
};

export default PrivateRoute;
