import { ConnectError, ApiError } from './Error'
import { useAuthStore } from '../store/authStore'

/**
 * Fetch wrapper
 * @throws {ConnectError}
 * @throws {ApiError}
 * @throws {SyntaxError}
 */
export const fetcher = async<ResponsePayload = any> (relativeUri: string, init: RequestInit = {}): Promise<ResponsePayload | null> => {
  const { accessToken } = useAuthStore.getState()

  const url = new URL(relativeUri, import.meta.env.VITE_API_URL)
  const headers = new Headers(init.headers)

  // Set content type only when body is present
  if (init.body) {
    headers.set('Content-Type', 'application/json')
  }

  if (accessToken) {
    headers.set('Authorization', `Bearer ${accessToken}`)
  }

  const request = new Request(url, {
    method: 'GET',
    headers,
    credentials: 'include',
    ...init,
  })

  let response: Response

  try {
    response = await window.fetch(request)
  } catch (typeError: any) {
    throw new ConnectError(request, { cause: typeError as TypeError })
  }

  // Do not parse when no content
  if (response.status === 204) {
    return null
  }

  /** @throws {SyntaxError} - Note: Should check for proper content type*/
  const responseJson = await response.json()

  if (!response.ok) {
    throw new ApiError(request, response, responseJson)
  }

  return responseJson
}
