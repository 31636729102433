import { IonContent, IonPage } from '@ionic/react';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import styles from './NotFound.module.scss'

const NotFound: preact.FunctionComponent = () => {
  return (
    <IonPage>
      <MenuHeader />
      <IonContent className={styles.container} forceOverscroll={false}>
        <h2>Nie znaleziono strony</h2>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
