import type { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

import { useAuthStore } from '../../store/authStore'

/**
 * Show fallback component until preferences are set to related stores
 * Prevent initial Login component flash
 */
const PreferencesProvider: FunctionComponent<{
  fallback: JSX.Element,
}> = ({
  fallback,
  children,
}) => {
  const [ isReady, setIsReady ] = useState<boolean>(false)
  const initializeAccessToken = useAuthStore(state => state.initializeAccessToken)

  useEffect(() => {
    initializeAccessToken()
      .then(() => setIsReady(true))
  }, [])

  return isReady ? children as JSX.Element : fallback
}

export default PreferencesProvider
