import { useState } from 'preact/hooks';
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom';
import { IonContent, IonPage, useIonToast } from '@ionic/react';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import { ROUTES } from '../../navigation';
import { useIonViewEffect } from '../../hooks/useIonView';
import { useGameSessionStore } from '../../store/gameSessionStore';

const SessionReader: preact.FunctionComponent = () => {
  const [hasError, setHasError] = useState(false);
  const login = useGameSessionStore((state) => state.login);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const sessionToken = queryParams.get('token');

  const [presentToast] = useIonToast()

  useIonViewEffect(() => {
    if (location.pathname === ROUTES.SESSION) {
      if (sessionToken) {
        login(sessionToken)
          .then(() =>
            presentToast({
              message: 'Zalogowałeś się do gry',
              duration: 5_000,
            })
          )
          .then(() =>
            history.push(ROUTES.GAME_START)
          )
          .catch(() => {
            setHasError(true)
          })
      } else {
        setHasError(true)
      }
    }
  }, [sessionToken]);

  return (
    <IonPage>
      <MenuHeader />
      <IonContent forceOverscroll={false}>
        {hasError
          ?
            <h2 className="ion-text-center">
              Błędny kod QR, spróbuj ponownie
            </h2>
          : null
        }
      </IonContent>
    </IonPage>
  )
};

export default SessionReader;
