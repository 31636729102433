import { useEffect } from 'preact/hooks'
import { App as CapacitorApp } from '@capacitor/app'
import type { BackButtonEvent } from '@ionic/core'
import { useIonRouter } from '@ionic/react'

/**
 * Native platform handlers
 */
const NativeHandlers: preact.FunctionComponent = () => {
  const ionRouter = useIonRouter()

  /**
   * Handle back button
   * Event fired only on Android platform (iOS has no harware back button)
   * @link https://ionicframework.com/docs/developing/hardware-back-button#exiting-the-app
   */
  useEffect(() => {
    document.addEventListener('ionBackButton', handleIonBackButton)

    return () => document.removeEventListener('ionBackButton', handleIonBackButton)

    function handleIonBackButton (ev: BackButtonEvent) {
      ev.detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
          CapacitorApp.exitApp()
        }
      })
    }
  }, [ionRouter])

  return null
}

export default NativeHandlers
