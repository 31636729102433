import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { Preferences } from '@capacitor/preferences'

export type AccessToken = string

/** Key which is used to store value by @capacitor/preferences */
const PREFERENCES_KEY: string = 'accessToken'

interface AuthState  {
  accessToken: AccessToken | null,

  /** Populate initial state from persistent storage */
  initializeAccessToken: () => Promise<void>,

  /** Set */
  setAccessToken: (accessToken: AccessToken) => Promise<void>,

  /** Remove */
  removeAccessToken: () => Promise<void>
}

export const useAuthStore = create<AuthState>()(devtools((set) => ({
  accessToken: null,

  initializeAccessToken: () =>
    Preferences.get({ key: PREFERENCES_KEY })
      .then(({ value }) => set({ accessToken: value}))
  ,

  setAccessToken: (accessToken) => {
    set({ accessToken })

    return Preferences.set({ key: PREFERENCES_KEY, value: accessToken })
  },

  removeAccessToken: () => {
    set({ accessToken: null })

    return Preferences.remove({ key: PREFERENCES_KEY })
  },
}), { store: 'ApiStore' }))
