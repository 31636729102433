import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in'

import { useAuthStore } from './authStore'
import { fetcher } from '../utils/fetcher'

export type CredentialResponse = {
  credential: string
}

export enum AgeCategory {
  Youth = 'Youth',
  Junior = 'Junior',
  Open = 'Open',
}

export enum Sex {
  F = 'F',
  M = 'M',
}

export enum MenWeightCategory {
  LTE_57 = '-57',
  R_58_66 = '58-66',
  R_67_77 = '67-77',
  R_78_93 = '78-93',
  GTE_93 = '94-',
}

export enum WomenWeightCategory {
  LTE_52 = '-52',
  R_53_65 = '53-65',
  GTE_66 = '66-',
}

type TUser = {
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  nickName: string,
  sex: Sex | null,
  ageCategory: AgeCategory | null,
  weightCategory: MenWeightCategory | WomenWeightCategory | null,
};

interface IUserState {
  isFetchingData: boolean,
  data: TUser | null,
  getUserData: () => Promise<void>,
  update: (user: TUser) => Promise<void>,
  delete: (userId: number) => Promise<void>,
  logout: () => Promise<void>,
  login: (res: CredentialResponse) => Promise<void>,
  loginWithApple: (signInWithAppleResponse: SignInWithAppleResponse) => Promise<void>,
  getUserScores: (userId: number, startDate: Date, endDate: Date) => Promise<void>,
}

const { setAccessToken, removeAccessToken } = useAuthStore.getState()

export const useUserStore = create<IUserState>()(devtools((set) => ({
  data: null,
  isFetchingData: false,
  getUserData: async () => {
    set({
      isFetchingData: true
    });

    await fetcher('player/profile')
    .then(json => {
      return set({ data: {
        id: json.id,
        firstName: json.givenName,
        lastName: json.familyName,
        email: json.email,
        nickName: json.nickName,
        sex: json.sex,
        ageCategory: json.ageCategory,
        weightCategory: json.weightCategory,
      }})
    })
    .finally(() => {
      set({
        isFetchingData: false,
      });
    })
  },
  update: async (user: TUser) => {
    await fetcher('player/profile', {
      method: 'PUT',
      body: JSON.stringify({
        id: user.id,
        email: user.email,
        givenName: user.firstName,
        familyName: user.lastName,
        nickName: user.nickName,
        sex: user.sex,
        ageCategory: user.ageCategory,
        weightCategory: user.weightCategory,
      }),
    })
    .then(() => {
      set({ data: user })
    });
  },
  delete: async(userId: number) => {
    await fetcher('player/profile', {
      method: 'DELETE',
    })
    .then(() => {
      set({ data: null });
    });

    await removeAccessToken()
  },
  logout: async () => {
    await fetcher('player/auth/sign-out', {
      method: 'POST',
    })
    await removeAccessToken()

    set({ data: null })
  },
  login: async (res: CredentialResponse) => {
    await fetcher('player/auth/google-sign-in', {
      method: 'POST',
      body: JSON.stringify({ credential: res.credential }),
    })
    .then(async (json) => {
      await setAccessToken(json.accessToken)

      return set({ data: {
        id: json.player.id,
        firstName: json.player.givenName,
        lastName: json.player.familyName,
        email: json.player.email,
        nickName: json.player.nickName,
        sex: json.player.sex,
        ageCategory: json.player.ageCategory,
        weightCategory: json.player.weightCategory,
      }})
    })
  },
  loginWithApple: async (signInWithAppleResponse) => {
    await fetcher('player/auth/apple-sign-in', {
      method: 'POST',
      body: JSON.stringify(signInWithAppleResponse.response)
    })
    .then(async (json) => {
      await setAccessToken(json.accessToken)

      return set({
        data: {
          id: json.player.id,
          firstName: json.player.givenName,
          lastName: json.player.familyName,
          email: json.player.email,
          nickName: json.player.nickName,
          sex: json.player.sex,
          ageCategory: json.player.ageCategory,
          weightCategory: json.player.weightCategory,
        }
      })
    })
  },
  getUserScores: async (userId: number, startDate: Date, endDate: Date) => {
    await fetcher(`game/scores/player/${userId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
    .then(json => set((state: any) => {
      return {
        data: {
          ...state.data,
          score: { ...json },
        }
      }
    }))
  }
}), { store: 'UserStore' }));
