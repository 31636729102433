import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in'
import {
  IonButton,
  IonIcon,
  isPlatform,
  useIonToast,
} from '@ionic/react'

import { useUserStore } from '../../store/userStore'

import styles from './AppleSignIn.module.scss'

import appleImgSrc from './apple.svg'

/**
 * [Capacitor Sign in With Apple]{@link https://github.com/capacitor-community/apple-sign-in}
 * [Sign in with Apple]{@link https://developer.apple.com/sign-in-with-apple/}
 * [Resolving Sign in with Apple response errors]{@link https://developer.apple.com/documentation/technotes/tn3107-resolving-sign-in-with-apple-response-errors}
 */
const AppleSignIn: preact.FunctionComponent = () => {
  const loginWithApple = useUserStore(state => state.loginWithApple)

  const [presentToast] = useIonToast()

  const handleSignInButtonClick: React.MouseEventHandler<HTMLIonButtonElement> = async () => {
    /**
     * With usePopup: true (default for @capacitor-community/apple-sign-in)
     * response is sent back to opener which muset be set up as SignInWithAppleOptions.redirectURL and  Return URL defined for Service
     */
    const options: SignInWithAppleOptions = {
      clientId: isPlatform('capacitor')
        // Capacitor appId
        ? 'network.boxer.player.app'
        // Apple Service Id
        : 'network.boxer.player',
      // Web app only
      redirectURI: window.location.href,
      scopes: 'email name',
    }

    /**
     * Note: Capacitor uses different format than one used by from Apple SignInResponse
     * @see https://developer.apple.com/documentation/sign_in_with_apple/signinresponsei
     */
    let result: SignInWithAppleResponse

    try {
      result = await SignInWithApple.authorize(options)
    } catch (error) {
      // error type {{ error: Error | string }}
      // web platform: {{ error: 'popup_closed_by_user' }}
      // ios platform: {{ error: Error("The operation couldn't be completed. (com.apple.AuthenticationServices.AuthorizationError error 1000.)") }}
      return presentToast({
        message: 'Błąd logowania',
        duration: 5_000,
      })
    }

    if (!result.response?.identityToken) {
      return presentToast({
        message: 'Błąd logowania',
        duration: 5_000,
      })
    }

    try {
      await loginWithApple(result)
    } catch (error) {
      return presentToast({
        message: 'Błąd logowania',
        duration: 5_000,
      })
    }
  }

  return (
    <IonButton
      fill="outline"
      onClick={handleSignInButtonClick}
    >
      <IonIcon
        slot="start"
        src={appleImgSrc}
        className={styles.appleIcon}
      />
      Zaloguj się przez Apple
    </IonButton>
  )
}

export default AppleSignIn
