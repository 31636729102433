import { IonContent, IonPage } from '@ionic/react';
import { shallow } from 'zustand/shallow'
import { useState } from 'preact/hooks';
import { useIonViewEffect } from '../../hooks/useIonView';
import { useUserStore } from '../../store/userStore';
import { useScoresStore } from '../../store/scoresStore';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import SessionResultModal from '../../components/SessionResultsModal/SessionResultsModal';
import { ROUTES } from '../../navigation';
import styles from './GameStart.module.scss';
import trophy from '../../assets/trophy.svg';
import medal from '../../assets/medal.svg';
import logo from '../../assets/logo.svg';

type GameStartProps = {
  history: any,
}

type UserScore = {
  max: number,
  rank: number,
}

const GameStart: preact.FunctionComponent<GameStartProps> = ({ history }) => {
  const [userScore, setUserScore] = useState<UserScore | null>(null);
  const { userData } = useUserStore(
    (state) => ({
      userData: state.data
    }),
  )

  const { getScores, data } = useScoresStore(
    (state) => ({
      getScores: state.getScores,
      data: state.data
    }),
    shallow
  )

  useIonViewEffect(() => {
    const today = new Date();
    const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    getScores(firstDayOfCurrentMonth, today);
  }, [])

  useIonViewEffect(() => {
    if (!data || !userData) {
      return
    }

    const scoresSortedByMax = data?.sort((a, b) => b.max - a.max);
    const userMaxScore = scoresSortedByMax?.find((score) => score.player.id === Number(userData?.id))?.max || 0;
    const userRank = scoresSortedByMax?.findIndex((score) => score.player.id === Number(userData?.id)) || 0;

    setUserScore({
      max: userMaxScore,
      rank: userRank > 0 ? userRank + 1 : 0
    })
  }, [data, userData])

  const onPlayClick = () => {
    history.push(ROUTES.PINCODE);
  }

  const onPlayerClick = () => {
    history.push(ROUTES.PROFILE);
  }

  const onResultsClick = () => {
    history.push(ROUTES.GAME_RANKING);
  }

  return (
    <IonPage id="main">
      <MenuHeader />
      <IonContent className={styles.container} forceOverscroll={false}>
        <div className={styles.gamePanelContainer}>
          <div role="button" onClick={onPlayerClick} className={styles.playerContainer}>
            {
              userData?.nickName && (
                <img src={logo} width="42" height="42" />
              )
            }
            {
              userData?.nickName && (
                <h3 style={{
                  fontSize: userData?.nickName.length > 9 ? 15 : 22,
                }}>{userData?.nickName}</h3>
              )
            }
          </div>
          <div className={styles.resultsContainer}>
            <div className={styles.medalContainer} onClick={onResultsClick} role="button">
              <img src={medal} width="30" />
              <span>{userScore?.rank || '---'}</span>
            </div>
            <div className={styles.trophyContainer} onClick={onResultsClick} role="button">
              <img src={trophy} width="30" />
              <span>{userScore?.max || '---'}</span>
            </div>
          </div>
        </div>
        <SessionResultModal />
        <button className={styles.playButton} type="button" onClick={onPlayClick}>
          Play
        </button>
      </IonContent>
    </IonPage>
  );
};

export default GameStart;
