import { IonContent, IonPage } from '@ionic/react';
import MenuHeader from '../../components/MenuHeader/MenuHeader';
import AgreementsContent from '../../components/AgreementsContent/AgreementsContent';
import styles from './Agreements.module.scss';

const NotFound: preact.FunctionComponent = () => {
  return (
    <IonPage>
      <MenuHeader />
      <IonContent className={styles.container}>
        <AgreementsContent />
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
