import type { ToastOptions } from '@ionic/react';
import { IonButton, IonIcon, useIonToast } from '@ionic/react';
import { useEffect, useState } from 'preact/hooks';
import { CapacitorException } from '@capacitor/core'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { type CredentialResponse, useUserStore } from '../../store/userStore';

import styles from './GoogleLogin.module.scss'
import googleImgSrc from './google.svg'

/**
 * @see https://developers.google.com/identity/sign-in/web/reference -> Error codes
 */
type GSIWebError = { error: string }

const isPullRequestPreview = import.meta.env.VITE_HOSTING_IS_PULL_REQUEST === 'true'
const prPreviewLoginUrl = new URL(import.meta.env.VITE_PR_PREVIEW_LOGIN_URL)

// TODO: Move to pages/Login/Login.tsx
const Login: preact.FunctionComponent = () => {
  const login = useUserStore((state) => state.login)

  const [presentToast] = useIonToast()

  const onSuccess = async (res: CredentialResponse) => {
    await login(res);
  }

  const onError = (error: CapacitorException | GSIWebError) => {
    console.log('Login failed', error)

    const toastOptions: ToastOptions = {
      message: 'Błąd logowania',
      duration: 5_000,
    }

    // Native platform (CapacitorException & code?: string)
    if (error instanceof CapacitorException) {
      // Do nothing, user choose Cancel (error.code === '12501')
      if (error.message === 'The user canceled the sign-in flow.') {
        return
      }

      toastOptions.message = `${toastOptions.message} (kod: ${error.code ?? '-'})`
    // Web
    } else {
      // Do noting, user closed popup
      if (error.error === 'popup_closed_by_user') {
        return
      }

      toastOptions.message = `${toastOptions.message} (kod: ${error.error})`
    }

    presentToast(toastOptions)
  }

  const [popup, setPopup] = useState<Window|null>(null)

  // TODO: Use useIonViewEffect when next to IonPage component
  useEffect(() => {
    if (!popup || popup.closed) {
      return
    }

    // Handle incoming messages
    const handleMessageEvent = (messageEvent: MessageEvent<
      { type: 'google-login-ready' } |
      { type: 'google-login-success', credentialResponse: CredentialResponse } |
      { type: 'google-login-error' }
    >): void => {
      if (messageEvent.origin !== prPreviewLoginUrl.origin) {
        return
      }

      switch (messageEvent.data.type) {
        // Send empty message so popup can verify openers origin
        case 'google-login-ready':
          return popup.postMessage({ type: 'opener-verification' }, prPreviewLoginUrl.origin)

        case 'google-login-success':
          onSuccess(messageEvent.data.credentialResponse)
          break

        /*
        // Not implemented
        case 'google-login-error':
          onError()
          break
        */

        default:
          return
      }

      // Clean up
      popup.close()

      setPopup(null)
    }

    window.addEventListener('message', handleMessageEvent)

    return () => window.removeEventListener('message', handleMessageEvent)
  }, [popup])

  const handlePrPreviewButtonClick = () => {
    if (popup && !popup.closed) {
      return popup.focus()
    }

    setPopup(window.open(prPreviewLoginUrl, 'pr-preview-login', 'popup=yes'))
  }

  // Note: Additional props: authentication.accessToken, serverAuthCode
  const handleSignInButtonClick: React.MouseEventHandler<HTMLIonButtonElement> = async (event) => {
    try {
      const response = await GoogleAuth.signIn()

      onSuccess({ credential: response.authentication.idToken })
    } catch (error) {
      onError(error as CapacitorException | GSIWebError)
    }
  }

  return (
    <IonButton
      fill="outline"
      onClick={!isPullRequestPreview
        // Normal login
        ? handleSignInButtonClick
        // Login with production app
        : handlePrPreviewButtonClick
      }
    >
      <IonIcon
        slot="start"
        src={googleImgSrc}
        className={styles.googleIcon}
      />
      Zaloguj się przez Google
    </IonButton>
  );
};

export default Login;
