import { render } from 'preact';
import GoogleAuthProvider from './components/GoogleAuthProvider/GoogleAuthProvider'

import App from './App';

const clientId = import.meta.env.VITE_GOOGLE_API_CLIENT_ID;

// Initialize mock responses
if (import.meta.env.MODE === 'development') {
  const { worker } = await import('./mocks/browser')
  // Warn about unhandled requests to API, ignore rest (assets, chrome-extension)
  await worker.start({
    onUnhandledRequest(request, print) {
      if (request.url.startsWith(import.meta.env.VITE_API_URL)) {
        print.warning()
      }
    },
  })
}

const container = document.getElementById('root');
render(
  <GoogleAuthProvider clientId={clientId}>
    <App />
  </GoogleAuthProvider>,
  container!
);
