import { IonContent, IonPage } from '@ionic/react';
import MenuHeader from '../components/MenuHeader/MenuHeader';

const TermsAndConditions: preact.FunctionComponent = () => {
  return (
    <IonPage>
      <MenuHeader />
      <IonContent className="ion-padding">
        <h2>
          Regulamin aplikacji SMART BOXER:
        </h2>
        <ol>
          <li>
            Niniejszy Regulamin określa zasady korzystania z aplikacji SMART BOXER, której właścicielem jest Gravitacja Michał Świerz z siedzibą w ul. Kosów 4, 40-541 Katowice (dalej zwany Usługodawcą)
          </li>
          <li>
            Każdy Użytkownik aplikacji jest zobowiązany do zaakceptowania i do przestrzegania regulaminu.
          </li>
          <li>
            Regulamin określa zasady funkcjonowania w społeczności WBC i w aplikacji SMART BOXER.
          </li>
          <li>
            Użytkownicy ponoszą odpowiedzialność za treści, które zamieszczają w aplikacji, Usługodawca ma prawo usuwać treści, które naruszają regulamin.
          </li>
          <li>
            Utworzenie konta w aplikacji jest możliwe za pomocą konta Google lub Apple ID.
          </li>
          <li>
            Usługodawca ma prawo do zamieszczania w aplikacji treści reklamowych dotyczących oferowanych usług, jak i towarów i usług osób trzecich.
          </li>
          <li>
            Aby korzystać z aplikacji, Użytkownik musi mieć ukończone 13 lat.
          </li>
          <li>
            W aplikacji zabronione jest używanie wulgaryzmów, mowy nienawiści, podszywanie się pod inną osobę,
            <ul>
              <li>
                jeśli użytkownik wybierze nick, imię lub nazwisko niezgodne z regulaminem bądź naruszające czyjeś prawa, zastrzegamy sobie prawo do zmiany tego identyfikatora (zgodnie z punktem 4 niniejszego regulaminu).
              </li>
            </ul>
          </li>
          <li>
            Usunięcie konta: Użytkownik może w dowolnym momencie usunąć swoje konto, w zakładce profil gracza w aplikacji mobilnej.
          </li>
          <li>
            Aplikacja SMART BOXER przestrzega przepisów dotyczących ochrony danych osobowych, w tym Ogólnego Rozporządzenia o Ochronie Danych Osobowych (RODO). Dane osobowe użytkowników są przechowywane i przetwarzane zgodnie z polityką prywatności dostępną w aplikacji.
          </li>
          <li>
            Wszelkie materiały graficzne, teksty, logo oraz inne treści zamieszczone w aplikacji SMART BOXER są chronione prawem autorskim. Wszelkie prawa do tych treści należą do Usługodawcy, chyba że wskazano inaczej.
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do wprowadzania zmian w niniejszym regulaminie w dowolnym czasie.
            <ul>
              <li>
                Użytkownicy zostaną powiadomieni o zmianach za pomocą komunikatu w aplikacji.
              </li>
              <li>
                Kontynuując korzystanie z aplikacji po wprowadzeniu zmian, użytkownicy akceptują nową wersję regulaminu.
              </li>
            </ul>
          </li>
        </ol>
        <h3>
          Instrukcja użytkowania urządzenia:
        </h3>
        <ul>
          <li>Przeczytaj instrukcje</li>
          <li>Opłać grę</li>
          <li>Naciśnij “START”</li>
          <li>Uderz w gruszkę</li>
        </ul>
        <p>
          Gracz używa maszyny wyłącznie na własne ryzyko. Właściciel, producent i sprzedawca detaliczny nie są odpowiedzialni za szkody, zarówno te fizyczne, jak i psychiczne, powstałe w wyniku użytkowania maszyny. Zaleca się, by na maszynie nie grały osoby będące w stanie zdrowia ograniczającym swobodne korzystanie z automatu.
        </p>
        <h3>
          Przed rozpoczęciem gry:
        </h3>
        <ol>
          <li>
            Przed naciśnięciem przycisku start gracz musi stać na wprost boxera.
          </li>
          <li>
            Po przyciśnięciu przycisku start gracz musi poczekać przed bokserem na uwolnienie gruszki.
          </li>
          <li>
            Po uwolnieniu gruszki gracz powinien zrobić pięść w ten sposób, by kciuk był na zewnątrz palca wskazującego i środkowego.
          </li>
          <li>
            Gracz musi stać w odległości ⅔ długości swojego ramienia od gruszki.
          </li>
          <li>
            Należy uderzać w środek gruszki.
          </li>
          <li>
            Po uderzeniu na ekranie i wyświetlaczu pojawi się wynik gracza.
          </li>
          <li>
            W kolejnej grze należy powtórzyć czynności.
          </li>
        </ol>
        <h3>
          Zabrania się:
        </h3>
        <ol>
          <li>
            Uderzania w maszynę inną częścią ciała niż ręką.
          </li>
          <li>
            Uderzania w inne miejsce niż w gruszkę.
          </li>
          <li>
            Uderzania w urządzenie z rozbiegu.
          </li>
          <li>
            Przebywania w odległości 1,5m od urządzenia, podczas gdy korzysta z niego inna osoba.
          </li>
          <li>
            Korzystania z urządzenia, bez uprzedniego zapoznania się z regulaminem i instrukcją użytkowania.
          </li>
        </ol>
      </IonContent>
    </IonPage>
  )
};

export default TermsAndConditions;
